.dashboard-container {
  display: flex;
  height: 100vh;
}

.main-section {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
}

.main-content {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
  color: black;
  overflow-y: auto;
}
