.topbar-custom {
  background-color: #272727;
  color: white;
  display: flex;
  justify-content: space-between; /* Cambiado a space-between para alinear a la izquierda y derecha */
  align-items: center;
  padding: 0 20px;
  height: 50px;
}

.topbar-info {
  display: flex;
  align-items: center;
  width: 100%; /* Asegura que ocupe todo el ancho disponible */
}

.username {
  flex: 1; /* Ocupa todo el espacio disponible a la izquierda */
  text-align: left; /* Alinea el texto a la izquierda */
}

.logout-button {
  background: none; /* Elimina el fondo del botón */
  border: none; /* Elimina el borde del botón */
  cursor: pointer; /* Cambia el cursor al pasar por encima */
  padding: 0; /* Elimina el padding */
  margin-left: 10px;
}

.logout-icon {
  width: 20px;
  height: 20px;
}
