.sidebar-custom {
  background-color: #171717;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  height: 100vh;
  width: 200px; /* Ajusta el ancho de la sidebar */
  overflow-y: auto; /* Permite el scroll vertical si es necesario */
  overflow-x: hidden; /* Oculta el scroll horizontal */
}

.sidebar-logo {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}

.sidebar-icon {
  margin-right: 10px;
}

.sidebar-custom .nav-link {
  color: white;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.sidebar-custom .nav-link:hover {
  background-color: #495057;
  border-radius: 5px;
}

.sidebar-custom .accordion-button {
  color: white;
  background-color: #171717;
  border: none;
}

.sidebar-custom .accordion-button:focus {
  box-shadow: none;
}

.sidebar-custom .accordion-button:not(.collapsed) {
  color: white;
  background-color: #343a40;
}

.sidebar-custom .accordion-item {
  background-color: #171717;
  border: none;
}

.sidebar-custom .accordion-body {
  padding-left: 0;
  padding-right: 0;
}

.sidebar-custom::-webkit-scrollbar {
  width: 8px; /* Ancho del scrollbar */
}

.sidebar-custom::-webkit-scrollbar-track {
  background: #2c2f33; /* Color de fondo del track */
}

.sidebar-custom::-webkit-scrollbar-thumb {
  background-color: #09ff00; /* Color del thumb */
  border-radius: 10px; /* Radio del borde para un diseño redondeado */
  border: 2px solid #2c2f33; /* Espacio alrededor del thumb */
}
