.login-container {
  height: 100vh;
  background-color: #111;
}

.login-card {
  background-color: #333;
  border-radius: 15px;
  padding: 20px;
  width: 300px;
}

.login-logo {
  width: 100px; /* Ajusta el tamaño del logo */
  height: 100px;
  margin-bottom: 30px; /* Aumenta el margen inferior para separar el logo */
}

.login-input {
  background-color: #444;
  border: none;
  color: #fff;
  margin-bottom: 20px; /* Aumenta el margen inferior para separar los inputs */
}

.login-input::placeholder {
  color: #ccc;
}

.login-input:focus {
  background-color: #444;
  border-color: #666; /* Puedes ajustar esto para que combine con el fondo */
  box-shadow: none; /* Remueve el box-shadow azul predeterminado de Bootstrap */
  color: #fff;
}

.login-button {
  background-color: #444;
  border: none;
  width: 80%; /* Ajusta el tamaño del botón */
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}
