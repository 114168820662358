.proceso-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.proceso-imagen {
  width: 150px; /* Ajusta el tamaño según sea necesario */
  margin-bottom: 20px;
}

.proceso-container h2 {
  color: #333;
  margin-bottom: 10px;
}

.proceso-container p {
  color: #666;
}
